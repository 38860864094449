import { Component, OnInit, SystemJsNgModuleLoader } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../servicios/auth.service';
import { ComunicarPropiedadesService } from '../servicios/comunicar-propiedades.service';
import  * as md5 from 'md5'
import { IdiomaService } from '../servicios/idioma.service';
import { AppInfo } from '../interfaces/login';
import { TranslateService } from '@ngx-translate/core';
import { ExpRegulares } from '../Beans/ExpRegulares.model';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['../app.component.css']
})
export class LoginComponent implements OnInit {

  username: string;
  password: string;
  appSelected: AppInfo ={
    idApp:-1,
    name:''
  };
  appList: AppInfo[];
  usuarioActivo = null;



  msgError:string = "";
  submitting: boolean = false;
  submitMessage: string = '';

  constructor(private authService: AuthService,
    private router: Router,
    private comunicaPropiedadesService: ComunicarPropiedadesService,
    private idiomaService: IdiomaService,
    private translateService: TranslateService) { }
  async ngOnInit() {
    //IDIOMA
    this.idiomaService.load();
    this.resetear();
    //LOAD APP LIST
    /*this.authService.getAppList().then(value=>{
      console.log("CONSUMO PROMISE app list (LOGIN) ")
      this.appList=value;
    });*/
    /*Promise.resolve().then(async()=>{
      await this.authService.loadAppList();
      console.log("LOGN- RECUPERO LISTA1: ", this.appList)
    });
    */
    this.submitting=true;
    this.submitMessage='loading app list...'
    // console.log("LOAD INI")
    this.authService.loadAppList().then(value=>{
      this.appList = value;
      // console.log("login. AppList: ", this.appList);
    }).catch(reason=>{
      // console.log("ERROR /app", reason);
      this.msgError = this.translateService.instant('error.execution');
    }).finally(()=>{
      this.submitting=false;
      this.submitMessage='';
    });


    this.comunicaPropiedadesService.showMenu(false);
  }
  /*
    Logging y guarda el token de la sesión
  */
  login(){

    const user = {
      username: this.username,
      password: !!this.password?md5(this.password):'',
      app: this.appSelected.idApp
    }
    this.msgError="";
    this.submitting=true;
    this.submitMessage='loggin...'
    // console.log("INICIO LOGIN", user);

    Promise.resolve().then(async () => {
      try {
        let logCorrecto = await this.authService.login(user,this.password);
        if (logCorrecto) {
          this.usuarioActivo = this.authService.getUserLogged();
          this.comunicaPropiedadesService.usuarioActivo = this.usuarioActivo;
          this.comunicaPropiedadesService.opConfig = "0";
          this.comunicaPropiedadesService.showMenu(true);
        } else {
          this.usuarioActivo = null;
          this.comunicaPropiedadesService.usuarioActivo = this.usuarioActivo;
          this.msgError=this.translateService.instant('errorLogin');
        }
        console.log("");
      } catch (e) {
            console.log(e);
            this.msgError = this.translateService.instant('error.execution');
      }
      this.submitting=false;
      this.submitMessage='';
    });

    // console.log("FIN LOGIN")
    /*this.authService.login(user).subscribe({
      next: (data)=>{
        console.log("login next: ", data)
        if(data.length==1){
          //Hay datos login
          const userLogged = {...user, rol: data.rol};
          this.authService.loginUser(userLogged);
          this.comunicaPropiedadesService.showMenu(true);
          this.router.navigateByUrl("/")
        }else{
          this.msgError=data.error;
        }
        this.submitted=false;
      },
      error: (err)=>console.log("ERROR when call login"),
      complete:()=>console.log("fINISH login")
    })*/
  }

  setApp(data:AppInfo){
    this.appSelected = data;
    // console.log("SETaPP ", data)
  }

  resetear() {

      this.comunicaPropiedadesService.expAAplicar = new ExpRegulares();
      this.comunicaPropiedadesService.configJSON = new ExpRegulares();
      this.comunicaPropiedadesService.nombreModelo = "";
      this.comunicaPropiedadesService.testML = "10%";
      this.comunicaPropiedadesService.palabras = "1000";
      this.comunicaPropiedadesService.fichero = null;
      this.comunicaPropiedadesService.columnaEntrada = [];
      this.comunicaPropiedadesService.columnasSalida = [];
      this.comunicaPropiedadesService.keysNews = [];
      this.comunicaPropiedadesService.listaPalabras = [];
      this.comunicaPropiedadesService.generacionAuto = "5";
      this.comunicaPropiedadesService.dimPob = "50";
      this.comunicaPropiedadesService.rfc = false;
      this.comunicaPropiedadesService.logistica = false;
      this.comunicaPropiedadesService.nb = false;
      this.comunicaPropiedadesService.svc = false;
      this.comunicaPropiedadesService.kne = false;
      this.comunicaPropiedadesService.estimaVal = "20";
      this.comunicaPropiedadesService.mDepth = "10";
      this.comunicaPropiedadesService.cWeight = "None";
      this.comunicaPropiedadesService.estimaAdaVal = "20";
      this.comunicaPropiedadesService.criterioVal = "entropy";
      this.comunicaPropiedadesService.iteraVal = "2000";
      this.comunicaPropiedadesService.kernelVal = "poly";
      this.comunicaPropiedadesService.kernelValSel = "";
      this.comunicaPropiedadesService.probable = false;
      this.comunicaPropiedadesService.vecinosVal = "10";
      this.comunicaPropiedadesService.metricaVal = "minkowski";
      this.comunicaPropiedadesService.base64Excel = null;
      this.comunicaPropiedadesService.salidaLlamada = null;
      this.comunicaPropiedadesService.expregop = true;
      this.comunicaPropiedadesService.rnn = false;
      this.comunicaPropiedadesService.pargenopt = true;
      this.comunicaPropiedadesService.dropoutRNN = "20%";
      this.comunicaPropiedadesService.optimizerVal = "adam";
      this.comunicaPropiedadesService.perdidaVal = "categorical_crossentropy";
      this.comunicaPropiedadesService.metricaCompVal = "accuracy";
      this.comunicaPropiedadesService.loteTMVal = "100";
      this.comunicaPropiedadesService.epocasVal = "10";
      this.comunicaPropiedadesService.capasIn = [];
      this.comunicaPropiedadesService.capasInConv = [];
      this.comunicaPropiedadesService.iniKernelVal = "uniform";
      this.comunicaPropiedadesService.iniKernelConvVal = "uniform";
      this.comunicaPropiedadesService.actiVal = "relu";
      this.comunicaPropiedadesService.outLayer = "32";
      this.comunicaPropiedadesService.rnnConv = false;
      this.comunicaPropiedadesService.pargenoptConv = true;
      this.comunicaPropiedadesService.dropoutRNNConv = "20%";
      this.comunicaPropiedadesService.optimizerConvVal = "adam";
      this.comunicaPropiedadesService.perdidaConvVal = "categorical_crossentropy";
      this.comunicaPropiedadesService.metricaCompConvVal = "accuracy";
      this.comunicaPropiedadesService.loteTMConvVal = "100";
      this.comunicaPropiedadesService.epocasConvVal = "10";
      this.comunicaPropiedadesService.actiConvVal = "relu";
      this.comunicaPropiedadesService.outLayerConv = "32";
      this.comunicaPropiedadesService.filtrosConvVal = "16";
      this.comunicaPropiedadesService.tamKernelConv = "16";
      this.comunicaPropiedadesService.paddingConvVal = "same";
      this.comunicaPropiedadesService.actiConvVal2 = "relu";
      this.comunicaPropiedadesService.embeddingConvVal = "32";
      this.comunicaPropiedadesService.rnnRec = false;
      this.comunicaPropiedadesService.pargenoptRec = true;
      this.comunicaPropiedadesService.dropoutRNNRec = "20%";
      this.comunicaPropiedadesService.optimizerRecVal = "adam";
      this.comunicaPropiedadesService.perdidaRecVal = "categorical_crossentropy";
      this.comunicaPropiedadesService.metricaCompRecVal = "accuracy";
      this.comunicaPropiedadesService.loteTMRecVal = "100";
      this.comunicaPropiedadesService.epocasRecVal = "10";
      this.comunicaPropiedadesService.iniKernelRecVal = "uniform";
      this.comunicaPropiedadesService.actiRecVal = "relu";
      this.comunicaPropiedadesService.outLayerRec = "32";
      this.comunicaPropiedadesService.capaRecVal = "32";
      this.comunicaPropiedadesService.embeddingRecVal = "32";
      this.comunicaPropiedadesService.opcionElegida = "1";
      this.comunicaPropiedadesService.listaIdiomas = [];
      this.comunicaPropiedadesService.adaboost = false;
      this.comunicaPropiedadesService.gradientboosting = false;
      this.comunicaPropiedadesService.aplica = "";
      this.comunicaPropiedadesService.opConfig = "0";
      this.comunicaPropiedadesService.convertir = false;
      this.comunicaPropiedadesService.maymin = "0";
      this.comunicaPropiedadesService.signos = false;
      this.comunicaPropiedadesService.espacios = false;
      this.comunicaPropiedadesService.agrupacion = false;
      this.comunicaPropiedadesService.elimClass = false;
      this.comunicaPropiedadesService.numMinimoEli = 1;
      this.comunicaPropiedadesService.numMinimo = 1;
      this.comunicaPropiedadesService.nombreClase = "";
      this.comunicaPropiedadesService.iniClus = 2;
      this.comunicaPropiedadesService.finClus = 2;

  }

}
