import { Component, OnInit } from '@angular/core';
import { AppInfo, UserTableInfo } from 'src/app/interfaces/login';
import { AuthService } from 'src/app/servicios/auth.service';
import { IdiomaService } from 'src/app/servicios/idioma.service';
import  * as md5 from 'md5'

import { TranslateService } from '@ngx-translate/core';
import { ComunicarPropiedadesService } from 'src/app/servicios/comunicar-propiedades.service';
@Component({
  selector: 'app-gestion-usuarios',
  templateUrl: './gestion-usuarios.component.html',
  styleUrls: ['../../app.component.css']
})
export class GestionUsuariosComponent implements OnInit {

  username:string;
  appInfo: AppInfo={
    idApp:-1,
    name:''
  };
  showCreate:boolean=false;
  listUsers:UserTableInfo[];
  addName:string;
  addRol:string;
  addPass1:string;
  addPass2:string;
  submiting:boolean=false;
  msgError:string="";
  indiceBorrar: number = null;
  idBorrar: number = null;
  idModApl: number = null;
  idModUsu: number = null;
  opcion: String = "";

  constructor(public authService: AuthService,
    private idiomaService: IdiomaService,
    private comunicarPropiedadesService: ComunicarPropiedadesService,
    private translateService: TranslateService) {

    }

  ngOnInit() {

    this.comunicarPropiedadesService.opcionElegida = "4";

    this.idiomaService.load();

    this.appInfo = this.authService.getAppUserLogged();
  if (!this.authService.isAdmin()) {
      this.showCreate = true;
      this.opcion = "1";
      this.username = this.authService.getUserLogged().username;
      // console.log(this.listUsers);
      const filterBy={
        username: this.username,
        app: this.appInfo.idApp
      }
      this.authService.getUsers(filterBy).then(data=>{
        this.listUsers = data;
       this.idModUsu = this.listUsers[0].idUser;
       this.addName = this.listUsers[0].username;
       this.addRol = this.listUsers[0].rol;
       this.idModApl = this.listUsers[0].idApp;
        // console.log("DATA: ",this.listUsers)
       }).catch(err =>{
         // console.log("ERROR search", err);
         this.msgError = this.translateService.instant('error.execution');
       }).finally(()=>{
         this.submiting=false;
       });
    }
    
  }

  search(){
    this.msgError = "";
    this.username = (!!this.username && !!(this.username.trim()))?this.username:undefined;
    const filterBy={
      username: this.username,
      app: this.appInfo.idApp
    }
    this.submiting=true;
    this.authService.getUsers(filterBy).then(data=>{
       this.listUsers = data;
       // console.log("DATA: ",data)
      }).catch(err =>{
        // console.log("ERROR search", err);
        this.msgError = this.translateService.instant('error.execution');
      }).finally(()=>{
        this.submiting=false;
      });
  }
  editUser(index: number){
    // console.log("EDITING USER: " + index)
    // console.log(this.listUsers[index])
  }

  toogleCreate(){

    this.msgError = "";
    if(!this.showCreate){
      this.cleanCreate();
    }
    this.showCreate = !this.showCreate;
    this.opcion = "0";
  }

  toogleModifica(){

    this.msgError = "";
    this.showCreate = !this.showCreate;
    this.opcion = "1";
  }

  async addNewUser(){

    this.msgError = "";
    if(!!!this.addName || this.addName.trim().length ==0){
      const field = this.translateService.instant("users.create.user");
      this.msgError=this.translateService.instant("users.error.emptyField",{field})
    }else if(!!!this.addRol || this.addRol.trim().length ==0){
      const field = this.translateService.instant("users.create.rol");
      this.msgError=this.translateService.instant("users.error.emptyField",{field})
    } else if(!!!this.addPass1 || this.addPass1.trim().length ==0){
      const field = this.translateService.instant("users.create.password");
      this.msgError=this.translateService.instant("users.error.emptyField",{field})
    }else if(this.addPass1 != this.addPass2){
      this.msgError= this.translateService.instant('users.error.passwd');
    }else{
      this.submiting=true;
      const dato = {
        username: this.addName,
        password: md5(this.addPass1),
        app: this.appInfo.idApp,
        rol: this.addRol,
        passwordN: ""
      }
      this.authService.register(dato).then(value=>{
        // console.log("REGISTER THEN: ", value)
      this.showCreate=false;
      this.search();
      }).catch(reason=>{
        // console.log("ERROR /register", reason);
        this.msgError = this.translateService.instant('error.execution') + " - " + reason.error;
      }).finally(()=>{
        this.submiting=false;
      });
    }
  }
  deleteUser(){
    this.msgError = "";
    this.submiting = true;
    this.authService.deleteUser(this.idBorrar).then(value=>{
      this.listUsers.splice(this.indiceBorrar,1);
    }).catch(reason=>{
      // console.log("ERROR /deleteUser", reason);
      this.msgError = this.translateService.instant('error.execution');
    }).finally(()=>{
      this.submiting=false;
      this.salirverModalBorrar();
    });
  }
  private cleanCreate(){
    this.addName="";
    this.addRol="";
    this.addPass1="";
    this.addPass2="";
    //this.errorCreate="";
    this.msgError="";
  }
  modifyUser(index: number){
    const btnPen = document.getElementById("btnPen"+index);
    const btnDelete = document.getElementById("btnDelete"+index);
    btnPen.style.display="none";
    btnDelete.style.display="none";

    const btnModifSave = document.getElementById("btnModifSave"+index);
    const btnModifCancel = document.getElementById("btnModifCancel"+index);
    btnModifSave.style.display="inherit";
    btnModifCancel.style.display="inherit";

    const usernameField = document.getElementById("username" + index);
    const rolField = document.getElementById("rol" + index);
    const pass1 = document.getElementById("password1_" + index);
    const pass2 = document.getElementById("password2_" + index);
    const dataCol2_ = document.getElementById("dataCol2_" + index);
    //Show password to change
    pass1.style.display="inherit";
    pass2.style.display="inherit";
    dataCol2_.style.display="none";

    //Reset values
    (<HTMLInputElement>pass1).value = "";
    (<HTMLInputElement>pass2).value = "";

    //Modo readonly
    (<HTMLInputElement>usernameField).disabled=false;
    (<HTMLSelectElement>rolField).disabled=false;
  }
  cancelModifyUser(index:number){
    const btnPen = document.getElementById("btnPen"+index);
    const btnDelete = document.getElementById("btnDelete"+index);
    btnPen.style.display="inherit";
    btnDelete.style.display="inherit";

    const btnModifSave = document.getElementById("btnModifSave"+index);
    const btnModifCancel = document.getElementById("btnModifCancel"+index);
    btnModifSave.style.display="none";
    btnModifCancel.style.display="none";
    const origin =  this.listUsers[index];
    const usernameField = document.getElementById("username" + index);
    const rolField = document.getElementById("rol" + index);
    const pass1 = document.getElementById("password1_" + index);
    const pass2 = document.getElementById("password2_" + index);
    const dataCol2 = document.getElementById("dataCol2_" + index);
    //Reset values
    (<HTMLInputElement>pass1).value = "";
    (<HTMLInputElement>pass2).value = "";
    //Show password to change
    pass1.style.display="none";
    pass2.style.display="none";
    dataCol2.style.display="inherit";

    //Colocar los datos originales
    (<HTMLInputElement>usernameField).value = origin.username;
    (<HTMLSelectElement>rolField).value = origin.rol;
    //Modo readonly
    (<HTMLInputElement>usernameField).disabled=true;
    (<HTMLSelectElement>rolField).disabled=true;
  }
  saveModifyUser(index:number){

    this.submiting = true;
    this.msgError="";
    const origin =  this.listUsers[index];

    const usernameField = document.getElementById("username" + index);
    const pass1 = document.getElementById("password1_" + index);
    const pass2 = document.getElementById("password2_" + index);
    const rolField = document.getElementById("rol" + index);
    if(((<HTMLSelectElement>pass1).value.length>0 ||
    (<HTMLSelectElement>pass2).value.length>0) &&
    (<HTMLSelectElement>pass1).value.trim() !== (<HTMLSelectElement>pass2).value.trim()){
      this.msgError=this.translateService.instant("users.error.passwd");
    }else{
      //Si hay cambios lanzar la actualizacion
      if(origin.rol != (<HTMLSelectElement>rolField).value ||
        (<HTMLInputElement>pass1).value.trim().length>0 ||
        origin.username != (<HTMLSelectElement>usernameField).value ){
        //La validacion lo hace el back.
        const data = {
          idUser: origin.idUser,
          username: (<HTMLInputElement>usernameField).value,
          password: undefined,
          rol: (<HTMLSelectElement>rolField).value,
          app: this.appInfo.idApp
        }
        if((<HTMLInputElement>pass1).value.trim().length>0){
          data.password = md5((<HTMLInputElement>pass1).value.trim())
        }
        this.authService.updateUser(data).then(value=>{
          // console.log("FIN UPDATE. Response: ", value)
          this.updateOk(index);
        }).catch(error=>{
          this.msgError=this.translateService.instant("error.execution");
        }).finally(()=>{
          this.submiting=false;
        })
      }else{
        // console.log("NO NEED UPDATE!")
        this.updateOk(index);
      }
    }
  }
  private updateOk(index:number){
    const usernameField = document.getElementById("username" + index);
    const rolField = document.getElementById("rol" + index);
    const btnPen = document.getElementById("btnPen"+index);
    const btnDelete = document.getElementById("btnDelete"+index);
    const pass1 = document.getElementById("password1_" + index);
    const pass2 = document.getElementById("password2_" + index);
    const dataCol2 = document.getElementById("dataCol2_" + index);

    //Reset values
    (<HTMLInputElement>pass1).value = "";
    (<HTMLInputElement>pass2).value = "";
    //Ocultar/Mostrar
    pass1.style.display="none";
    pass2.style.display="none";
    dataCol2.style.display="inherit";

    btnPen.style.display="inherit";
    btnDelete.style.display="inherit";

    const btnModifSave = document.getElementById("btnModifSave"+index);
    const btnModifCancel = document.getElementById("btnModifCancel"+index);
    btnModifSave.style.display="none";
    btnModifCancel.style.display="none";
    //Modo readonly
    (<HTMLInputElement>usernameField).disabled=true;
    (<HTMLSelectElement>rolField).disabled=true;
  }

  rolNuevo(rol: String) {
    this.addRol = rol.toString();
  }

  cancelaNewUser() {
    this.toogleCreate();
  }

  verModalBorrar(index:number, idUser:number) {
    this.indiceBorrar = index;
    this.idBorrar = idUser;
    Array.from(document.getElementsByClassName("modal") as HTMLCollectionOf<HTMLElement>)[0].style.display="block";
  }

  salirverModalBorrar() {
    this.indiceBorrar = null;
    this.idBorrar = null;
    Array.from(document.getElementsByClassName("modal") as HTMLCollectionOf<HTMLElement>)[0].style.display="none";
  }

  modificar(usuario){
    this.addName = usuario.username;
    this.addRol = usuario.rol;
    this.addPass1 = "";
    this.addPass2 = "";
    this.idModApl = usuario.idApp
    this.idModUsu = usuario.idUser

    this.toogleModifica();

  }

  modificarUser() {
    if((this.addPass1.length>0 ||
      this.addPass2.length>0) &&
      this.addPass1.trim() !== this.addPass2.trim()){
      this.msgError=this.translateService.instant("users.error.passwd");
    }else{
      //La validacion lo hace el back.

      const data = {
        idUser: this.idModUsu,
        username: this.addName,
        password: undefined,
        rol: this.addRol,
        app: this.idModApl
      }

      if(this.addPass1.trim().length>0){
        data.password = md5(this.addPass1.trim())
      }
      this.authService.updateUser(data).then(value=>{
        // console.log("FIN UPDATE. Response: ", value)
        if (this.authService.isAdmin()) {
          this.showCreate=false;
          this.search();
        }
      }).catch(error=>{
        this.msgError=this.translateService.instant("error.execution");
      }).finally(()=>{
        this.submiting=false;
      })
      
    }
  }

}

