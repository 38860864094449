import { Component, OnInit } from '@angular/core';
import { ComunicarPropiedadesService } from '../servicios/comunicar-propiedades.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-complejos',
  templateUrl: './complejos.component.html',
  styleUrls: ['../app.component.css']
})
export class ComplejosComponent implements OnInit {
  
  constructor(private comunicarPropiedadesService: ComunicarPropiedadesService,
              private translateService: TranslateService,
              private router: Router) { }

  rfc: boolean = false;
  logistica: boolean = false;
  nb: boolean = false;
  svc: boolean = false;
  kne: boolean = false;
  adaboost: boolean = false;
  gradientboosting: boolean = false;
  estimaVal: String = "";
  mDepth: String = "";
  cWeight: String = "";
  estimaAdaVal: String = "";
  criterioVal: String = "";
  iteraVal: String = "2000";
  kernelVal: String = "poly";
  kernelValSel: String = "";
  probable: boolean = false;
  vecinosVal: String = "10";
  metricaVal: String = "minkowski";
  erroresEntrada = [];
  tipoInfo: String = "";
  rnn: boolean = false;
  rnnConv: boolean = false;
  rnnRec: boolean = false;
  pargenopt: boolean = true;
  pargenoptConv: boolean = true;
  pargenoptRec: boolean = true;
  dropoutRNN: String = "20%";
  dropoutRNNConv: String = "20%";
  dropoutRNNRec: String = "20%";
  optimizerVal: String = "adam";
  optimizerConvVal: String = "adam";
  optimizerRecVal: String = "adam";
  perdidaVal: String = "categorical_crossentropy";
  perdidaConvVal: String = "categorical_crossentropy";
  perdidaRecVal: String = "categorical_crossentropy";
  metricaCompVal: String = "accuracy";
  metricaCompConvVal: String = "accuracy";
  metricaCompRecVal: String = "accuracy";
  loteTMVal: String = "100";
  loteTMConvVal: String = "100";
  loteTMRecVal: String = "100";
  epocasVal: String = "10";
  epocasConvVal: String = "10";
  epocasRecVal: String = "10";
  capasIn = [];
  capasInConv = [];
  iniKernelVal: String = "uniform";
  iniKernelConvVal: String = "uniform";
  iniKernelRecVal: String = "uniform";
  actiVal: String = "relu";
  actiConvVal: String = "relu";
  actiConvVal2: String = "relu";
  actiRecVal: String = "relu";
  outLayer: String = "32";
  outLayerConv: String = "32";
  outLayerRec: String = "32";
  filtrosConvVal: String = "16";
  tamKernelConv: String = "16";
  paddingConvVal: String = "same";
  embeddingConvVal: String = "32";
  embeddingRecVal: String = "32";
  capaRecVal: String = "32";
  opErrores: string = "";
  
  ngOnInit() {
    this.comunicarPropiedadesService.opcionElegida = "3";
    this.rfc = this.comunicarPropiedadesService.rfc;
    this.logistica = this.comunicarPropiedadesService.logistica;
    this.nb = this.comunicarPropiedadesService.nb;
    this.svc = this.comunicarPropiedadesService.svc;
    this.kne = this.comunicarPropiedadesService.kne;
    this.estimaVal = this.comunicarPropiedadesService.estimaVal;
    this.mDepth = this.comunicarPropiedadesService.mDepth;
    this.cWeight = this.comunicarPropiedadesService.cWeight;
    this.criterioVal = this.comunicarPropiedadesService.criterioVal;
    this.iteraVal = this.comunicarPropiedadesService.iteraVal;
    this.kernelVal = this.comunicarPropiedadesService.kernelVal;
    // Solo si this.kernelVal es distinto de ""
    if (this.kernelVal!="") {
      this.comunicarPropiedadesService.kernelValSel = this.translateService.instant(this.kernelVal.toString());
    }
    this.kernelValSel = this.comunicarPropiedadesService.kernelValSel;
    this.probable = this.comunicarPropiedadesService.probable;
    this.vecinosVal = this.comunicarPropiedadesService.vecinosVal;
    this.metricaVal = this.comunicarPropiedadesService.metricaVal;
    this.rnn = this.comunicarPropiedadesService.rnn;
    this.pargenopt = this.comunicarPropiedadesService.pargenopt;
    this.pargenoptConv = this.comunicarPropiedadesService.pargenoptConv;
    this.pargenoptRec = this.comunicarPropiedadesService.pargenoptRec;
    this.dropoutRNN = this.comunicarPropiedadesService.dropoutRNN;
    this.dropoutRNNConv = this.comunicarPropiedadesService.dropoutRNNConv;
    this.dropoutRNNRec = this.comunicarPropiedadesService.dropoutRNNRec;
    this.optimizerVal = this.comunicarPropiedadesService.optimizerVal;
    this.optimizerConvVal = this.comunicarPropiedadesService.optimizerConvVal;
    this.optimizerRecVal = this.comunicarPropiedadesService.optimizerRecVal;
    this.perdidaVal = this.comunicarPropiedadesService.perdidaVal;
    this.perdidaConvVal = this.comunicarPropiedadesService.perdidaConvVal;
    this.perdidaRecVal = this.comunicarPropiedadesService.perdidaRecVal;
    this.metricaCompVal = this.comunicarPropiedadesService.metricaCompVal;
    this.metricaCompConvVal = this.comunicarPropiedadesService.metricaCompConvVal;
    this.metricaCompRecVal = this.comunicarPropiedadesService.metricaCompRecVal;
    this.loteTMVal = this.comunicarPropiedadesService.loteTMVal;
    this.loteTMConvVal = this.comunicarPropiedadesService.loteTMConvVal;
    this.loteTMRecVal = this.comunicarPropiedadesService.loteTMRecVal;
    this.epocasVal = this.comunicarPropiedadesService.epocasVal;
    this.epocasConvVal = this.comunicarPropiedadesService.epocasConvVal;
    this.epocasRecVal = this.comunicarPropiedadesService.epocasRecVal;
    this.capasIn = this.comunicarPropiedadesService.capasIn;
    this.capasInConv = this.comunicarPropiedadesService.capasInConv;
    this.iniKernelVal = this.comunicarPropiedadesService.iniKernelVal;
    this.iniKernelConvVal = this.comunicarPropiedadesService.iniKernelConvVal;
    this.iniKernelRecVal = this.comunicarPropiedadesService.iniKernelRecVal;
    this.actiVal = this.comunicarPropiedadesService.actiVal;
    this.actiConvVal = this.comunicarPropiedadesService.actiConvVal;
    this.actiConvVal2 = this.comunicarPropiedadesService.actiConvVal2;
    this.actiRecVal = this.comunicarPropiedadesService.actiRecVal;
    this.rnnConv = this.comunicarPropiedadesService.rnnConv;
    this.rnnRec = this.comunicarPropiedadesService.rnnRec;
    this.filtrosConvVal = this.comunicarPropiedadesService.filtrosConvVal;
    this.tamKernelConv = this.comunicarPropiedadesService.tamKernelConv;
    this.paddingConvVal = this.comunicarPropiedadesService.paddingConvVal;
    this.embeddingConvVal = this.comunicarPropiedadesService.embeddingConvVal;
    this.embeddingRecVal = this.comunicarPropiedadesService.embeddingRecVal;
    this.capaRecVal = this.comunicarPropiedadesService.capaRecVal;
    this.outLayerRec = this.comunicarPropiedadesService.outLayerRec;
    this.adaboost = this.comunicarPropiedadesService.adaboost;
    this.gradientboosting = this.comunicarPropiedadesService.gradientboosting;
    this.estimaAdaVal = this.comunicarPropiedadesService.estimaAdaVal;

    
    let bot1 = document.getElementById("bot1");
    let bot2 = document.getElementById("bot2");
    if (this.pargenopt) {
      bot1.classList.add("active");
      bot2.classList.remove("active");
    } else {
      bot2.classList.add("active");
      bot1.classList.remove("active");
    }

    let bot1C = document.getElementById("bot1Conv");
    let bot2C = document.getElementById("bot2Conv");
    if (this.pargenoptConv) {
      bot1C.classList.add("active");
      bot2C.classList.remove("active");
    } else {
      bot2C.classList.add("active");
      bot1C.classList.remove("active");
    }

    let bot1R = document.getElementById("bot1Rec");
    let bot2R = document.getElementById("bot2Rec");
    if (this.pargenoptRec) {
      bot1R.classList.add("active");
      bot2R.classList.remove("active");
    } else {
      bot2R.classList.add("active");
      bot1R.classList.remove("active");
    }

  }
  
  cambioRfc() {
    this.comunicarPropiedadesService.rfc = this.rfc;
  }

  cambioLogistica() {
    this.comunicarPropiedadesService.logistica = this.logistica;
  }

  cambioNb() {
    this.comunicarPropiedadesService.nb = this.nb;
  }

  cambioSvc() {
    this.comunicarPropiedadesService.svc = this.svc;
  }

  cambioKne() {
    this.comunicarPropiedadesService.kne = this.kne;
  }

  cambioAdaboost() {
    this.comunicarPropiedadesService.adaboost = this.adaboost;
  }

  cambioGradientboosting() {
    this.comunicarPropiedadesService.gradientboosting = this.gradientboosting;
  }

  cambioRnn() {
    this.comunicarPropiedadesService.rnn = this.rnn;
  }

  cambioRnnConv() {
    this.comunicarPropiedadesService.rnnConv = this.rnnConv;
  }

  cambioRnnRec() {
    this.comunicarPropiedadesService.rnnRec = this.rnnRec;
  }

  verModal(modal: number) {
    Array.from(document.getElementsByClassName("modal") as HTMLCollectionOf<HTMLElement>)[modal].style.display="block";
  }

  salirverModal(modal: number) {
    Array.from(document.getElementsByClassName("modal") as HTMLCollectionOf<HTMLElement>)[modal].style.display="none";
  }

  infoMetodos(tipo: String) {

    this.tipoInfo = tipo;
    
    Array.from(document.getElementsByClassName("modal") as HTMLCollectionOf<HTMLElement>)[5].style.display="block";

  }

  estima(valor: String) {
    this.estimaVal = valor;
    this.comunicarPropiedadesService.estimaVal = this.estimaVal;
  }
  maDepth(valor: String) {
    this.mDepth = valor;
    this.comunicarPropiedadesService.mDepth = this.mDepth;
  }
  clWeight(valor: String) {
    this.cWeight = valor;
    this.comunicarPropiedadesService.cWeight = this.cWeight;
  }

  estimaAda(valor: String) {
    this.estimaAdaVal = valor;
    this.comunicarPropiedadesService.estimaAdaVal = this.estimaAdaVal;
  }

  iniKernelT(valor: String) {
    this.iniKernelVal = valor;
    this.comunicarPropiedadesService.iniKernelVal = this.iniKernelVal;
  }

  iniKernelTC(valor: String) {
    this.iniKernelConvVal = valor;
    this.comunicarPropiedadesService.iniKernelConvVal = this.iniKernelConvVal;
  }

  iniKernelTR(valor: String) {
    this.iniKernelRecVal = valor;
    this.comunicarPropiedadesService.iniKernelRecVal = this.iniKernelRecVal;
  }

  outLayerL(valor: String) {
    this.outLayer = valor;
    this.comunicarPropiedadesService.outLayer = this.outLayer;
  }

  outLayerLC(valor: String) {
    this.outLayerConv = valor;
    this.comunicarPropiedadesService.outLayerConv = this.outLayerConv;
  }

  outLayerLR(valor: String) {
    this.outLayerRec = valor;
    this.comunicarPropiedadesService.outLayerRec = this.outLayerRec;
  }

  paddingC(valor: String) {
    this.paddingConvVal = valor;
    this.comunicarPropiedadesService.paddingConvVal = this.paddingConvVal;
  }

  embeddingC(valor: String) {
    this.embeddingConvVal = valor;
    this.comunicarPropiedadesService.embeddingConvVal = this.embeddingConvVal;
  }

  embeddingR(valor: String) {
    this.embeddingRecVal = valor;
    this.comunicarPropiedadesService.embeddingRecVal = this.embeddingRecVal;
  }

  capaR(valor: String) {
    this.capaRecVal = valor;
    this.comunicarPropiedadesService.capaRecVal = this.capaRecVal;
  }

  actiT(valor: String) {
    this.actiVal = valor;
    this.comunicarPropiedadesService.actiVal = this.actiVal;
  }

  actiTC(valor: String) {
    this.actiConvVal = valor;
    this.comunicarPropiedadesService.actiConvVal = this.actiConvVal;
  }

  actiTC2(valor: String) {
    this.actiConvVal2 = valor;
    this.comunicarPropiedadesService.actiConvVal2 = this.actiConvVal2;
  }

  actiTR(valor: String) {
    this.actiRecVal = valor;
    this.comunicarPropiedadesService.actiRecVal = this.actiRecVal;
  }

  filtroC(valor: String) {
    this.filtrosConvVal = valor;
    this.comunicarPropiedadesService.filtrosConvVal = this.filtrosConvVal;
  }

  tamKernelC(valor: String) {
    this.tamKernelConv = valor;
    this.comunicarPropiedadesService.tamKernelConv = this.tamKernelConv;
  }

  perdida(valor: String) {
    this.perdidaVal = valor;
    this.comunicarPropiedadesService.perdidaVal = this.perdidaVal;
  }

  perdidaC(valor: String) {
    this.perdidaConvVal = valor;
    this.comunicarPropiedadesService.perdidaConvVal = this.perdidaConvVal;
  }

  perdidaR(valor: String) {
    this.perdidaRecVal = valor;
    this.comunicarPropiedadesService.perdidaRecVal = this.perdidaRecVal;
  }

  epocasN(valor: String) {
    this.epocasVal = valor;
    this.comunicarPropiedadesService.epocasVal = this.epocasVal;
  }

  epocasNC(valor: String) {
    this.epocasConvVal = valor;
    this.comunicarPropiedadesService.epocasConvVal = this.epocasConvVal;
  }

  epocasNR(valor: String) {
    this.epocasRecVal = valor;
    this.comunicarPropiedadesService.epocasRecVal = this.epocasRecVal;
  }

  loteTMF(valor: String) {
    this.loteTMVal = valor;
    this.comunicarPropiedadesService.loteTMVal = this.loteTMVal;
  }

  loteTMFC(valor: String) {
    this.loteTMConvVal = valor;
    this.comunicarPropiedadesService.loteTMConvVal = this.loteTMConvVal;
  }

  loteTMFR(valor: String) {
    this.loteTMRecVal = valor;
    this.comunicarPropiedadesService.loteTMRecVal = this.loteTMRecVal;
  }

  metricaComp(valor: String) {
    this.metricaCompVal = valor;
    this.comunicarPropiedadesService.metricaCompVal = this.metricaCompVal;
  }

  metricaCompC(valor: String) {
    this.metricaCompConvVal = valor;
    this.comunicarPropiedadesService.metricaCompConvVal = this.metricaCompConvVal;
  }

  metricaCompR(valor: String) {
    this.metricaCompRecVal = valor;
    this.comunicarPropiedadesService.metricaCompRecVal = this.metricaCompRecVal;
  }

  criterio(valor: String) {
    this.criterioVal = valor;
    this.comunicarPropiedadesService.criterioVal = this.criterioVal;
  }

  itera(valor: String) {
    this.iteraVal = valor;
    this.comunicarPropiedadesService.iteraVal = this.iteraVal;
  }

  kernel(valor: String) {
    this.kernelVal = valor;
    this.kernelValSel = this.translateService.instant(valor.toString());
    this.comunicarPropiedadesService.kernelVal = this.kernelVal;
    this.comunicarPropiedadesService.kernelValSel = this.kernelValSel;
  }

  cambioProbable() {
    this.comunicarPropiedadesService.probable = this.probable; 
  }

  vecinos(valor: String) {
    this.vecinosVal = valor;
    this.comunicarPropiedadesService.vecinosVal = this.vecinosVal;
  }

  metrica(valor: String) {
    this.metricaVal = valor;
    this.comunicarPropiedadesService.metricaVal = this.metricaVal;
  }

  lanzarMetodosComplejos() {

    this.erroresEntrada = [];
    this.opErrores = "0";

    var validaEntrada = this.comunicarPropiedadesService.validaEnt();
    
    if (validaEntrada.filter(elemento => elemento.criticidad == 0).length == 0) {
      if (validaEntrada.filter(elemento => elemento.criticidad == 1).length == 0) { // Sin errores ni warning
        this.comunicarPropiedadesService.lanzarGeneracionModelo("0");
        let er = {
          "errDato": this.translateService.instant('procesando'),
          "criticidad": "2"
        }
        validaEntrada.push(er);
      } else { // Con warning
        this.opErrores = "1";
      }
    } else { // Con errores
      this.opErrores = "2";
    }
    this.erroresEntrada = validaEntrada;
    Array.from(document.getElementsByClassName("modal") as HTMLCollectionOf<HTMLElement>)[4].style.display="block";
  
  }

  ejecutarConWar() {

    this.comunicarPropiedadesService.lanzarGeneracionModelo("0");
    this.salirverModal(4);
    
  }

  cambioPest(pest: boolean) {
    this.pargenopt = pest;
    this.comunicarPropiedadesService.pargenopt = this.pargenopt;
    let bot1 = document.getElementById("bot1");
    let bot2 = document.getElementById("bot2");
    if (pest) {
      bot1.classList.add("active");
      bot2.classList.remove("active");
    } else {
      bot2.classList.add("active");
      bot1.classList.remove("active");
    }
  }

  cambioPestConv(pest: boolean) {
    this.pargenoptConv = pest;
    this.comunicarPropiedadesService.pargenoptConv = this.pargenoptConv;
    let bot1 = document.getElementById("bot1Conv");
    let bot2 = document.getElementById("bot2Conv");
    if (pest) {
      bot1.classList.add("active");
      bot2.classList.remove("active");
    } else {
      bot2.classList.add("active");
      bot1.classList.remove("active");
    }
  }

  cambioPestRec(pest: boolean) {
    this.pargenoptRec = pest;
    this.comunicarPropiedadesService.pargenoptRec = this.pargenoptRec;
    let bot1 = document.getElementById("bot1Rec");
    let bot2 = document.getElementById("bot2Rec");
    if (pest) {
      bot1.classList.add("active");
      bot2.classList.remove("active");
    } else {
      bot2.classList.add("active");
      bot1.classList.remove("active");
    }
  }

  dropRNN(par: String) {
    this.dropoutRNN = par;
    this.comunicarPropiedadesService.dropoutRNN = this.dropoutRNN;
  }

  dropRNNC(par: String) {
    this.dropoutRNNConv = par;
    this.comunicarPropiedadesService.dropoutRNNConv = this.dropoutRNNConv;
  }

  dropRNNR(par: String) {
    this.dropoutRNNRec = par;
    this.comunicarPropiedadesService.dropoutRNNRec = this.dropoutRNNRec;
  }

  optimizer(valor: String) {
    this.optimizerVal = valor;
    this.comunicarPropiedadesService.optimizerVal = this.optimizerVal;
  }

  optimizerC(valor: String) {
    this.optimizerConvVal = valor;
    this.comunicarPropiedadesService.optimizerConvVal = this.optimizerConvVal;
  }

  optimizerR(valor: String) {
    this.optimizerRecVal = valor;
    this.comunicarPropiedadesService.optimizerRecVal = this.optimizerRecVal;
  }

  insDatos() {
    this.capasIn.push({
      kernelInitializer: this.iniKernelVal,
      activation: this.actiVal,
      output: this.outLayer
    });
    this.comunicarPropiedadesService.capasIn = this.capasIn;
  }

  insDatosConv() {
    this.capasInConv.push({
      kernelInitializer: this.iniKernelConvVal,
      activation: this.actiConvVal,
      output: this.outLayerConv
    });
    this.comunicarPropiedadesService.capasInConv = this.capasInConv;
  }

  borrarFila(indice) {
    this.capasIn = this.capasIn.filter((elemento,ind) => ind != indice);
    this.comunicarPropiedadesService.capasIn = this.capasIn;
  }

  borrarFilaConv(indice) {
    this.capasInConv = this.capasInConv.filter((elemento,ind) => ind != indice);
    this.comunicarPropiedadesService.capasInConv = this.capasInConv;
  }

  mover(pos: any, tipo: String) {
    let comodin = this.capasIn[pos];
    if (tipo == "0") {
      this.capasIn[pos] = this.capasIn[pos - 1];
      this.capasIn[pos - 1] = comodin;
    } else {
      this.capasIn[pos] = this.capasIn[pos + 1];
      this.capasIn[pos + 1] = comodin;
    }

  }

  moverConv(pos: any, tipo: String) {
    let comodin = this.capasInConv[pos];
    if (tipo == "0") {
      this.capasInConv[pos] = this.capasInConv[pos - 1];
      this.capasInConv[pos - 1] = comodin;
    } else {
      this.capasInConv[pos] = this.capasInConv[pos + 1];
      this.capasInConv[pos + 1] = comodin;
    }

  }

  configuracion() {

    this.comunicarPropiedadesService.opConfig = "0";

    // this.router.navigateByUrl("/configuracion/${opcion}");
    this.router.navigateByUrl('/configuracion');
  }

}
