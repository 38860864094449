import { Component, OnInit, ViewChild, ElementRef, OnChanges } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ExpRegulares, ExpRegular } from '../Beans/ExpRegulares.model';
import { Palabra } from '../Beans/Palabra.model';
import { GestionArchivosService } from '../servicios/gestion-archivos.service';
import { TranslateService } from '@ngx-translate/core';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { ComunicarPropiedadesService } from '../servicios/comunicar-propiedades.service';
import { AuthService } from '../servicios/auth.service';

@Component({
  selector: 'app-configuracion',
  templateUrl: './configuracion.component.html',
  styleUrls: ['../app.component.css']
})
export class ConfiguracionComponent implements OnInit, OnChanges {

  nombreModelo: String="";
  testML: String = "10%";
  palabras: String = "1000";
  fichero: File;
  configJSON: ExpRegulares = new ExpRegulares();
  expAAplicar: ExpRegulares = new ExpRegulares();
  configText: string = "";
  desc: String = "";
  expr: String = "";
  sust: String = "";
  conNombre: boolean = false;
  @ViewChild('fileInput') fileInput: ElementRef;
  mensajeArchivo: String = "Se debe introducir un archivo";
  selecArchivo: boolean = false;
  columnaEntrada = [];
  columnasSalida = [];
  keysNews = [];
  jsonData = [];
  idiomaSel = "";
  // listaPalabras = new Array<Palabra>();
  listaPalabras = [];
  donde: String = "";
  tipo: String = "";
  base64Excel: any = null;
  expregop: boolean = true;
  palabr: String = "";
  modalTipo: String = "";
  listaIdiomas = [];
  convertir: boolean = false;
  maymin: string = "0";
  signos: boolean = false;
  espacios: boolean = false;
  agrupacion: boolean = false;
  elimClass: boolean = false;
  numMinimo: number = 1;
  numMinimoEli: number = 1;
  nombreClase: String = "";

  constructor(private gestionArchivosService: GestionArchivosService,
              private translateService: TranslateService,
              public comunicarPropiedadesService: ComunicarPropiedadesService,
              private authService: AuthService) {

              }

  ngOnInit() {
/*
    switch (this.comunicarPropiedadesService.opConfig) {
      case "1":
        this.salirverModal(3);
        this.verModal(0);
        break;
      case "2":
        this.salirverModal(0);
        this.verModal(3);
        break;
      default:
        this.salirverModal(0);
        this.salirverModal(3);
        break;
    }
*/
    this.comunicarPropiedadesService.opcionElegida = "1";

    /*this.http.get('./config.json', { responseType: 'text' }).subscribe((text) => {
      console.log(text);
    }); */

    this.mensajeArchivo = this.translateService.instant('archSel');

    // this.expAAplicar.expRegulares = new Array<ExpRegular>();

    /* this.comunicarPropiedadesService.expAAplicarObs.subscribe(expAAplicar => {
      this.expAAplicar = expAAplicar;
    });*/

    this.expAAplicar = this.comunicarPropiedadesService.expAAplicar;
    this.nombreModelo = this.comunicarPropiedadesService.nombreModelo;
    this.testML = this.comunicarPropiedadesService.testML;
    this.palabras = this.comunicarPropiedadesService.palabras;
    this.fichero = this.comunicarPropiedadesService.fichero;
    this.configJSON = this.comunicarPropiedadesService.configJSON;
    this.donde = "";
    this.handleFile(this.fichero);
    this.columnaEntrada = this.comunicarPropiedadesService.columnaEntrada;
    this.columnasSalida = this.comunicarPropiedadesService.columnasSalida;
    this.keysNews = this.comunicarPropiedadesService.keysNews;
    this.listaPalabras = this.comunicarPropiedadesService.listaPalabras;
    this.base64Excel = this.comunicarPropiedadesService.base64Excel;
    this.expregop = this.comunicarPropiedadesService.expregop;
    this.listaIdiomas = this.comunicarPropiedadesService.listaIdiomas;
    this.convertir = this.comunicarPropiedadesService.convertir;
    this.maymin = this.comunicarPropiedadesService.maymin;
    this.signos = this.comunicarPropiedadesService.signos;
    this.espacios = this.comunicarPropiedadesService.espacios;
    this.agrupacion = this.comunicarPropiedadesService.agrupacion;
    this.elimClass = this.comunicarPropiedadesService.elimClass;
    this.numMinimo = this.comunicarPropiedadesService.numMinimo;
    this.numMinimoEli = this.comunicarPropiedadesService.numMinimoEli;
    this.nombreClase = this.comunicarPropiedadesService.nombreClase;
    
    let bot1 = document.getElementById("bot1");
    let bot2 = document.getElementById("bot2");
    if (this.expregop) {
      bot1.classList.add("active");
      bot2.classList.remove("active");
    } else {
      bot2.classList.add("active");
      bot1.classList.remove("active");
    }

    Promise.resolve().then(async () => {
      try {
        const appLogin = this.authService.getAppUserLogged();
        const aplicacion = `${appLogin.name}`;
        const {username,passwordN} = this.authService.getUserLogged();
        this.configText = await this.gestionArchivosService.obtenerArchivoConfig(aplicacion,username,passwordN);
        // this.configJSON = JSON.parse(this.configText);
          } catch (e) {
            console.log("");
      }
    });
/*
    fetch('assets/config.json')
    .then(response => response.text())
    .then(text => {

      this.configJSON = JSON.parse(text);
      // this.configText = text;

    });
*/
  }

  ngOnChanges() {
    
  }

  cambioParamentros() {
    this.comunicarPropiedadesService.expAAplicar = this.expAAplicar;
    this.comunicarPropiedadesService.nombreModelo = this.nombreModelo;
    this.comunicarPropiedadesService.testML = this.testML;
    this.comunicarPropiedadesService.palabras = this.palabras;
  }

  modeloMLTest(testML: String) {
    this.testML = testML;
  }

  modeloMLPalabras(palabras: String) {
    this.palabras = palabras;
  }

  verModal(modal: number) {
    Array.from(document.getElementsByClassName("modal") as HTMLCollectionOf<HTMLElement>)[modal].style.display="block";
  }

  salirverModal(modal: number) {
    Array.from(document.getElementsByClassName("modal") as HTMLCollectionOf<HTMLElement>)[modal].style.display="none";
  }

  infoExcelMet(tipo: String) {
    this.tipo = tipo;
    Array.from(document.getElementsByClassName("modal") as HTMLCollectionOf<HTMLElement>)[1].style.display="block";
  }

  infoTestPal(tipo: String) {
    this.modalTipo = tipo;
    Array.from(document.getElementsByClassName("modal") as HTMLCollectionOf<HTMLElement>)[4].style.display="block";
  }

  pasarExp(expRegu: ExpRegular) {

    let correcta = true;
    try {
      new RegExp(expRegu.expression.toString());
    } catch (error) {
      correcta = false;
    }

    if (!correcta) {
      Array.from(document.getElementsByClassName("modal") as HTMLCollectionOf<HTMLElement>)[5].style.display="block";
    } else {
      this.expAAplicar.expRegulares.push(expRegu);
      // this.comunicarPropiedadesService.actualizarDatos(this.expAAplicar);
      this.comunicarPropiedadesService.expAAplicar = this.expAAplicar;
      this.configJSON.expRegulares = this.configJSON.expRegulares.filter(elemento => expRegu.description != elemento.description || expRegu.expression != elemento.expression || expRegu.replaces != elemento.replaces)
      this.comunicarPropiedadesService.configJSON = this.configJSON;
    }

  }

  devolverExp(expRegu: ExpRegular) {
    this.configJSON.expRegulares.push(expRegu);
    this.expAAplicar.expRegulares = this.expAAplicar.expRegulares.filter(elemento => expRegu.description != elemento.description || expRegu.expression != elemento.expression || expRegu.replaces != elemento.replaces)
    // this.comunicarPropiedadesService.actualizarDatos(this.expAAplicar);
    this.comunicarPropiedadesService.expAAplicar = this.expAAplicar;
    this.comunicarPropiedadesService.configJSON = this.configJSON;
  }

  subirExpr() {

    let correcta = true;
    try {
      new RegExp(this.expr.toString());
    } catch (error) {
      correcta = false;
    }

    if (this.desc != "" && this.expr != "" && correcta) {

      let expNew: ExpRegular = new ExpRegular();
      expNew.description = this.desc;
      expNew.expression = this.expr;
      expNew.replaces = this.sust;

      this.expAAplicar.expRegulares.push(expNew);
      // this.comunicarPropiedadesService.actualizarDatos(this.expAAplicar);
      this.comunicarPropiedadesService.expAAplicar = this.expAAplicar;


      this.desc = "";
      this.expr = "";
      this.sust = "";

    }

    if (!correcta) {
      Array.from(document.getElementsByClassName("modal") as HTMLCollectionOf<HTMLElement>)[5].style.display="block";
    }

  }

  subirConfig() {

    const appLogin = this.authService.getAppUserLogged();
    const aplicacion = `${appLogin.name}`;
    const {username,passwordN} = this.authService.getUserLogged();
    this.gestionArchivosService.guardarArchivoConfig(aplicacion,this.configText,username,passwordN);
    this.configJSON = JSON.parse(this.configText);
    this.comunicarPropiedadesService.configJSON = this.configJSON;
  }

  idiomaVoc(idioma) {
    this.idiomaSel=idioma;
  }

  consultaIdioma() {
    if (this.listaIdiomas.filter(elemento => elemento == this.idiomaSel).length == 0) {
      Promise.resolve().then(async () => {
        try {
              let salida = await this.gestionArchivosService.obtenerArchivoIdioma(this.idiomaSel);
              let nuevaSalida = salida.map(elemento => {
                let nuevoElemento: Palabra = new Palabra();
                nuevoElemento.palabra = elemento;
                nuevoElemento.idioma = this.idiomaSel;
                return nuevoElemento;
              });

              this.listaIdiomas.push(this.idiomaSel);

              this.listaPalabras.push(nuevaSalida);
              this.listaPalabras = this.listaPalabras.flat(1);
              this.comunicarPropiedadesService.listaPalabras = this.listaPalabras;
              this.comunicarPropiedadesService.listaIdiomas = this.listaIdiomas;

            } catch (e) {
            }
      });
    }
  }

  limpiarTabla() {
    this.listaPalabras = [];
    this.comunicarPropiedadesService.listaPalabras = this.listaPalabras;
    this.listaIdiomas = [];
    this.comunicarPropiedadesService.listaIdiomas = this.listaIdiomas;
  }

  bajarConfig() {
    Promise.resolve().then(async () => {
      try {
        const appLogin = this.authService.getAppUserLogged();
        const aplicacion = `${appLogin.name}`;
        const {username,passwordN} = this.authService.getUserLogged();
        this.configText = await this.gestionArchivosService.obtenerArchivoConfig(aplicacion,username,passwordN);
        this.configJSON = JSON.parse(this.configText);
        this.comunicarPropiedadesService.configJSON = this.configJSON;

          } catch (e) {
      }
    });
  }

  /* simularSeleccionArchivo(): void {
    const input = document.createElement('input');
    input.type = 'file';

    input.addEventListener('change', (e: Event) => {
      const file = (e.target as HTMLInputElement).files[0];
      console.log('Archivo seleccionado:', file);
      // Aquí puedes realizar las operaciones deseadas con el archivo seleccionado
    });

    input.click();
  }*/


  simularSeleccionArchivo(): void {
    this.donde="1";
    this.fileInput.nativeElement.click();
  }

  handleFile(file: File): void {

    if (this.donde == "1") {
      this.comunicarPropiedadesService.columnaEntrada = [];
      this.comunicarPropiedadesService.columnasSalida = [];
      this.comunicarPropiedadesService.keysNews = [];
    }
    this.columnaEntrada = [];
    this.columnasSalida = [];
    this.keysNews = [];
    if (file == undefined || file.name.indexOf(".xlsx") == -1) {
      this.mensajeArchivo = this.translateService.instant('archSel');
      this.conNombre=false;
      this.selecArchivo=false;
      this.base64Excel = null;
      this.comunicarPropiedadesService.base64Excel = this.base64Excel;
    } else {

      this.conNombre=true;
      this.mensajeArchivo = file.name;
      this.selecArchivo=true;
      const fileReader = new FileReader();
      this.verModal(6);
      fileReader.onload = (e: any) => {
        const arrayBuffer = e.target.result;
        const workbook = XLSX.read(arrayBuffer, { type: 'array' });
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        this.jsonData = XLSX.utils.sheet_to_json(worksheet);
        // let keys = Object.keys(this.jsonData[0]);
        let keys = Object.values(XLSX.utils.sheet_to_json(worksheet, { header: 1 })[0]);
        if (this.keysNews.length == 0 && this.comunicarPropiedadesService.columnaEntrada.length == 0 && this.comunicarPropiedadesService.columnasSalida.length == 0) {
          this.keysNews = keys.map((elemento,indice) => {
            let objCol = {
              indice: indice,
              elemento: elemento
            } 
            return objCol;
          });
        }
        this.salirverModal(6);
      };
      const fileReader2 = new FileReader();
      fileReader2.onload = (e: any) => {
        this.base64Excel = e.target.result;
        this.comunicarPropiedadesService.base64Excel = this.base64Excel;
      };
      fileReader.readAsArrayBuffer(file);
      fileReader2.readAsDataURL(file);

    }
    if (file != undefined && file.name.indexOf(".xlsx") > -1) {
      this.fichero = file;
      this.comunicarPropiedadesService.fichero = this.fichero;
    } else {
      this.fichero = undefined;
      this.comunicarPropiedadesService.fichero = this.fichero;
      if (file != undefined) {
        this.infoExcelMet("0");
      }
    }
  }

  dragOverHandler(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
  }

  dragEnterHandler(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
  }

  dragLeaveHandler(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
  }

  dropHandler(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    const files = event.dataTransfer.files;
    if (files.length > 0) {
      this.donde="1";
      this.handleFile(files[0]);
    }
  }

  addColEnt(key) {
    //if (this.columnaEntrada.length == 0) {
      this.columnaEntrada.push(key);
      this.comunicarPropiedadesService.columnaEntrada = this.columnaEntrada;
      this.keysNews = this.keysNews.filter(elemento => elemento.indice != key.indice);
      this.comunicarPropiedadesService.keysNews = this.keysNews;
    //}

  }
  addCol(key) {

    this.keysNews.push(key);
    this.comunicarPropiedadesService.keysNews = this.keysNews;
    this.columnaEntrada = this.columnaEntrada.filter(elemento => elemento.indice != key.indice);
    this.comunicarPropiedadesService.columnaEntrada = this.columnaEntrada;

  }

  addCol2(key) {

    this.keysNews.push(key);
    this.comunicarPropiedadesService.keysNews = this.keysNews;
    this.columnasSalida = this.columnasSalida.filter(elemento => elemento.indice != key.indice);
    this.comunicarPropiedadesService.columnasSalida = this.columnasSalida;

  }

  addColSal(key) {

    this.columnasSalida.push(key);
    this.comunicarPropiedadesService.columnasSalida = this.columnasSalida;
    this.keysNews = this.keysNews.filter(elemento => elemento.indice != key.indice);
    this.comunicarPropiedadesService.keysNews = this.keysNews;

  }

  cambioPest(pest: boolean) {
    this.expregop = pest;
    this.comunicarPropiedadesService.expregop = this.expregop;
    let bot1 = document.getElementById("bot1");
    let bot2 = document.getElementById("bot2");
    if (pest) {
      bot1.classList.add("active");
      bot2.classList.remove("active");
    } else {
      bot2.classList.add("active");
      bot1.classList.remove("active");
    }
  }

  quitaPalabra(key: String) {
    this.listaPalabras = this.listaPalabras.filter(elemento => elemento != key);
    this.comunicarPropiedadesService.listaPalabras = this.listaPalabras;

    this.listaIdiomas = Array.from(new Set(this.listaPalabras.map(elemento => elemento.idioma)));
    this.comunicarPropiedadesService.listaIdiomas = this.listaIdiomas;
  }

  masPalabras() {

    if (this.palabr != "") {
      let nuevoElemento: Palabra = new Palabra();
      nuevoElemento.palabra = this.palabr;
      nuevoElemento.idioma = "origins";
      this.listaPalabras.push(nuevoElemento);

      if (this.listaIdiomas.filter(elemento => elemento == "origins").length == 0) {
        this.listaIdiomas.push("origins");
      }

      this.palabr="";
    }
  }

  mover(pos: any, tipo: String) {
    let comodin = this.columnasSalida[pos];
    if (tipo == "0") {
      this.columnasSalida[pos] = this.columnasSalida[pos - 1];
      this.columnasSalida[pos - 1] = comodin;
    } else {
      this.columnasSalida[pos] = this.columnasSalida[pos + 1];
      this.columnasSalida[pos + 1] = comodin;
    }

  }

  moverEntrada(pos: any, tipo: String) {
    let comodin = this.columnaEntrada[pos];
    if (tipo == "0") {
      this.columnaEntrada[pos] = this.columnaEntrada[pos - 1];
      this.columnaEntrada[pos - 1] = comodin;
    } else {
      this.columnaEntrada[pos] = this.columnaEntrada[pos + 1];
      this.columnaEntrada[pos + 1] = comodin;
    }

  }

  confPredefinida() {

    fetch('assets/config.json')
    .then(response => response.text())
    .then(text => {

      this.configJSON = JSON.parse(text);
      this.configText = text;
      // this.configText = text;

    });

  }

  quitaIdioma(idioma: String) {

    this.listaPalabras = this.listaPalabras.filter(elemento => elemento.idioma != idioma);
    this.comunicarPropiedadesService.listaPalabras = this.listaPalabras;

    this.listaIdiomas = this.listaIdiomas.filter(elemento => elemento != idioma);
    this.comunicarPropiedadesService.listaIdiomas = this.listaIdiomas;

  }

  cambioConvertir() {

    this.comunicarPropiedadesService.convertir = this.convertir;

    if (this.convertir==true) {
      this.maymin = "1";
      this.comunicarPropiedadesService.maymin = this.maymin;
    }

  }

  cambioMayMin(opcion) {
    this.maymin = opcion;
    this.comunicarPropiedadesService.maymin = this.maymin;
  }

  cambioSignos() {
    this.comunicarPropiedadesService.signos = this.signos;
  }

  cambioEspacios() {
    this.comunicarPropiedadesService.espacios = this.espacios;
  }

  cambioAgrupacion() {
    this.comunicarPropiedadesService.agrupacion = this.agrupacion;
  }

  cambioElimClass() {
    this.comunicarPropiedadesService.elimClass = this.elimClass;
  }

  cambioNumMinimo() {

    this.numMinimo = Number(this.numMinimo.toString().replace(',','').replace('.','').replace('e',''))
    if (this.numMinimo < 1) {
      this.numMinimo = 1;
    }
    if (this.numMinimo > 3000) {
      this.numMinimo = 3000;
    }
    this.comunicarPropiedadesService.numMinimo = this.numMinimo;
    
  }

  cambioNumMinimoEli() {

    this.numMinimoEli = Number(this.numMinimoEli.toString().replace(',','').replace('.','').replace('e',''))
    if (this.numMinimoEli < 1) {
      this.numMinimoEli = 1;
    }
    if (this.numMinimoEli > 3000) {
      this.numMinimoEli = 3000;
    }
    this.comunicarPropiedadesService.numMinimoEli = this.numMinimoEli;
    
  }

  cambioNombreClase() {
    this.comunicarPropiedadesService.nombreClase = this.nombreClase;
  }

}
